import ProgressBar from "react-bootstrap/ProgressBar";
import {useEffect, useState} from "react";
import {ABI} from "../constants/abi";
import {connex, contract} from "../constants/contract";
import {CONTRACT} from "../constants";
import {Button, ButtonGroup, Dropdown, Table} from "react-bootstrap";
import {toast} from "react-toastify";

const Home = () => {
    const [inProgress, setInProgress] = useState(false)
    const [progressStatus, setProgressStatus] = useState(0);
    const [activeCollection, setActiveCollection] = useState(0)
    const [snapshot, setSnapshot] = useState([]);
    const [holders, setHolders] = useState([]);
    const [showCustom, setShowCustom] = useState(false);
    const findMethodABI = (ABI, method) => ABI[ABI.findIndex(mthd => mthd.name === method)]
    const sleep = m => new Promise(r => setTimeout(r, m))
    const projects = [
        // {
        //     'name': 'Union - bullys',
        //     'contract': '0xc9c8964cf25d2c738190f74b8508cdfac8650b9d',
        //     'maxSupply': 4444,
        // },
        // {
        //     'name': 'Union - memberships',
        //     'contract': '0x2f478c2e68e3385e632C625F0ee12D5a3A775e68',
        //     'maxSupply': 888,
        // },
        // {
        //     'name': 'Union - Tablets',
        //     'contract': '0x77fe6041fa5beb0172c9ab6014b4d8d5099f0a23',
        //     'maxSupply': 10000,
        // },
        // {
        //     'name': 'Vlippos',
        //     'contract': '0x81FC139f676736c96cbcBA40b5E5229BaEC02732',
        //     'maxSupply': 3333,
        // },
        // {
        //     'name': 'vebudz',
        //     'contract': '0xfc74715b3111909E63E0c0Afe73fFE7892755917',
        //     'maxSupply': 5000,
        // },
        // {
        //     'name': 'Thug paradise - Vethugs',
        //     'contract': '0x97F7C8d476183b69f18f810a18baf3f79994a267',
        //     'maxSupply': 10000,
        // },
        // {
        //     'name': 'Thug paradise - Vemons',
        //     'contract': '0x3dBBa9AD9E33bd188EEe8aa2d5c0e7B9894c6209',
        //     'maxSupply': 6666,
        // },
        // {
        //     'name': 'Thug paradise - VeShawties',
        //     'contract': '0xD56340abB721b7c89C6CA3835EFc490dFd66F9Ae',
        //     'maxSupply': 7777,
        // },
        // {
        //     'name': 'DoS - eggs',
        //     'contract': '0xd6B546368087D82a230A561c777Ca74776A1BB0C',
        //     'maxSupply': 2000,
        // },
        // {
        //     'name': 'DoS - baby dragons',
        //     'contract': '0xC22D8cA65Bb9EE4A8b64406f3B0405CC1EbeEc4e',
        //     'maxSupply': 2000,
        // },
        // {
        //     'name': 'DoS - Wild teens',
        //     'contract': '0xBb74D3D8305f6a6B49448746DE7F1c9EffaF0f82',
        //     'maxSupply': 1060,
        // },
        // {
        //     'name': 'DoS - Tamed Teens',
        //     'contract': '0x90dc145867F10EC90D4f4432431896Ca8f8Be0e3',
        //     'maxSupply': 2000,
        // },
        // {
        //     'name': 'DoS - Elementals',
        //     'contract': '0x6a4fc1661e9D4Ca8814Be52D155E2f6353b2782a',
        //     'maxSupply': 6000,
        // },
        // {
        //     'name': 'DoS - Weapons',
        //     'contract': '0xd4310196a56c5193811aE418B8729D82b34ABDCc',
        //     'maxSupply': 4444,
        // },
        {
            'name': 'Venonymous',
            'contract': '0x2FD3d1E1a3F1E072c89d67301a86a5ba850Ccd4E',
            'maxSupply': 2000,
        },
        {
            'name': 'Ganja Girls',
            'contract': '0x998C9d999Bd6AF31089E4d3Bc63A7D196f9A27cA',
            'maxSupply': 400,
        },
        // {
        //     'name': 'Vogies',
        //     'contract': '0x4a6b084243762dc219480edc5cfa0d88298bb707',
        //     'maxSupply': 1000,
        // },{
        //     'name': 'Warbands',
        //     'contract': '0x6aA982158617D53C37F65d43eb9a156449aDfFf3',
        //     'maxSupply': 10000,
        // },{
        //     'name': 'Blue Ape Gang',
        //     'contract': '0xa5e2ee50cb49ea4d0a3a520c15aa4cffaf5ea026',
        //     'maxSupply': 5000,
        // },{
        //     'name': 'bananacrack',
        //     'contract': '0xD7A8B0CEBED38164C463e39F9F433dAf963c5CFb',
        //     'maxSupply': 1000,
        // },
    ]

    const toggleActiveCustom = () => {
        setShowCustom(!showCustom);
    }

    const takeSnapshot = async () => {
        setInProgress(true)
        let contract = connex.thor.account(projects[activeCollection].contract);
        let showCustomAddressField = false;

        let collectionSize = 0
        if (projects[activeCollection].maxSupply) {
            collectionSize = projects[activeCollection].maxSupply;
        } else {
            collectionSize = (await contract.method(findMethodABI(ABI, 'totalSupply')).call()).decoded[0];
        }
        const ownerOfMethod = findMethodABI(ABI, 'ownerOf');
        let idWalletArray = [];
        let holdersArray = {};
        let promises = [];
        let fails = 0;
        setProgressStatus(0);
        for(let i = 1; i <= collectionSize; i = i + 50) {
            setProgressStatus((i / collectionSize) * 100);
            const batchedPromises = [];

            for(let x = i; x <= Math.min(collectionSize, i + 49); x++) {
                batchedPromises.push(new Promise((resolve, reject) => {
                    contract.method(ownerOfMethod).call(x).then((data) => {
                        holdersArray[data.decoded[0]] = 0;
                        resolve({key: x, value: data})
                    }).catch(() => {
                        fails = fails + 1;
                        console.log(x + ' failed');
                            reject()
                    })
                }))
            }

            promises = [...promises, ...await Promise.all(batchedPromises)];
            await sleep(100)
        }

        if (fails >= 1) {
            toast('there has bin errors, you may be missing data');
        }

        Promise.all(promises).then(values => {
            values.forEach((value, index) => {
                idWalletArray.push({'id': value.key, 'wallet': value.value.decoded[0]});
                holdersArray[value.value.decoded[0]] = holdersArray[value.value.decoded[0]] + 1;
            })
            setHolders({...holdersArray})
            setSnapshot([...idWalletArray])
            setInProgress(false)
        })
    }

    function HoldersTable(props) {

        return (
            <div>
                {Object.entries(props.holders).length > 0 ? <TableRow key={0} first={'wallet'} second={'holdings'} />: ''}
                {Object.entries(props.holders).map(([wallet, holdings]) =>
                    <TableRow key={wallet} first={wallet} second={holdings} />
                )}
            </div>
        );
    }

    function WalletTable(props) {
        return (
            <div>
                {Object.entries(props.wallets).length > 0 ? <TableRow key={0} first={'id'} second={'wallet'} /> : ''}
                {(props.wallets).map((wallet) =>
                    <TableRow key={wallet.id} first={wallet.id} second={wallet.wallet} />
                )}
            </div>
        )
    }

    function TableRow(props) {
        return <span>{props.first}, {props.second}<br /></span>;
    }

    return <div className="container">
        <div className="card">
            <div className="card-body">
                <div className="container">
                    {!inProgress ?
                        <div>
                            <Dropdown as={ButtonGroup}>
                                <Dropdown.Toggle variant={"success"}>
                                    {projects[activeCollection].name}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {projects.map((project, index) =>
                                        <Dropdown.Item onClick={() => setActiveCollection(index)} key={index} value={index}>{project.name}</Dropdown.Item>)
                                    }
                                </Dropdown.Menu>
                            </Dropdown>
                            {/*<input type="text" value={''} name={'customAddress'} id={"customAddress"} onChange={e => setActiveCollection(e.target.value)} style={{display: showCustom ? 'block': 'none'}} />*/}
                            {' '}<Button as={ButtonGroup} onClick={takeSnapshot} variant="primary">Take snapshot</Button></div>
                        : null }
                    {inProgress
                        ? <ProgressBar animated now={progressStatus} />
                        :
                        <div>
                            <nav>
                                <div className="nav nav-tabs" id="nav-tab" role="tablist" key="1">
                                    <button className="nav-link active" id="nav-snapshot-tab" data-bs-toggle="tab" data-bs-target="#nav-snapshot" type="button" role="tab" aria-controls="nav-snapshot" aria-selected="true">
                                        Snapshot
                                    </button>
                                    <button className="nav-link" id="nav-holders-tab" data-bs-toggle="tab" data-bs-target="#nav-holders" type="button" role="tab" aria-controls="nav-holders" aria-selected="false">
                                        Holders
                                    </button>
                                </div>
                            </nav>
                            <div className="tab-content" id="nav-tabContent" key="2">
                                <div key="2.1" className="tab-pane fade show active" id="nav-snapshot" role="tabpanel" aria-labelledby="nav-snapshot-tab">
                                    {<WalletTable wallets={snapshot}/>}
                                </div>
                                <div key="2.2" className="tab-pane fade" id="nav-holders" role="tabpanel" aria-labelledby="nav-holders-tab">
                                    {<HoldersTable holders={holders}/>}
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    </div>
};

export default Home;
